import API from "../services/services";

class ProductReleaseAPI {
  checkingBarcode(params) {
    return API.get(`blood_component_storage/check/${params}`);
  }

  listProlis(params) {
    return API.get("blood_component_storage/list", params);
  }

  add(payload) {
    return API.post("blood_component_storage/store", payload);
  }
  getDaily(params) {
    return API.get("blood_component_storage/get_daily_report", { params });
  }
}

export default new ProductReleaseAPI();
